<template>
    <div class="account">
        <div class="userTitle">
            <span>{{ $t('xiu-gai-you-xiang') }}</span>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: adminPath }">{{ $t('guan-li-zhong-xin') }}</el-breadcrumb-item>

                <el-breadcrumb-item :to="{ path: '/accountInfo' }">{{ $t('zhang-hu-yu-an-quan') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('xiu-gai-you-xiang') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="main">
            <el-form
                :label-width="isChinese ? '120px' : '200px'"
                :rules="rules"
                size="small"
                :model="form"
                style="width: 480px"
                ref="form"
                hide-required-asterisk
            >
                <el-form-item :label="$t('yan-zheng-deng-lu-mi-ma')" prop="password">
                    <el-input
                        v-model="form.password"
                        type="password"
                        :placeholder="$t('qing-shu-ru-deng-lu-mi-ma')"
                        maxlength="16"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('xin-you-xiang')" prop="email">
                    <el-input v-model="form.email" :placeholder="$t('qing-shu-ru-xin-de-you-xiang-di-zhi')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" @click="onSubmit" :loading="loading">{{ $t('que-ren') }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import formPage from '../../mixins/formPage';
export default {
    name: 'manage',
    mixins: [formPage],
    data() {
        return {
            form: {}
        };
    },
    methods: {
        submit() {
            this.$http
                .post('/user/changeEmail?password=' + this.form.password + '&email=' + this.form.email)
                .then(res => {
                    this.$store.dispatch('getUserInfo');
                    this.$message.success(this.$t('xiu-gai-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 0;
    border-bottom: 1px solid #bcc1cc;
    margin: 0 20px;
}

.main {
    padding: 20px;
}
.el-button {
    padding: 0 20px;
    line-height: 40px;
    min-width: 160px;
    margin-top: 32px;
}
</style>
